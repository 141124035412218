/**
 * @module smwebsdk
 */

/*
 * Copyright 2017-2020 Soul Machines Ltd. All Rights Reserved.
 */

import { Scene } from './Scene';
import { SmEvent } from './SmEvent';
import {
  ConversationSetVariablesRequestBody,
  StartSpeakingOptionalArgs,
  StartSpeakingRequestBody,
  PersonaRequestBody,
  ConversationSendRequestBody,
  AnimateToNamedCameraRequestBody,
  PlayAnimationRequestBody,
  StopBlProfilingRequestBody,
  GetModelFilterSearchResultRequestBody,
  GetModelChildrenRequestBody,
  GetModelVariableFilterSearchResultRequestBody,
  GetModelVariablesListRequestBody,
  GetVariablesListRequestBody,
  SetVariablesRequestBody,
  GetVariablesRequestBody,
} from './websocket-message/scene/index';
import { VariablesModel, AnimationModel } from './models/index';
import { PersonaId } from './models/PersonaId';

/**
 * Persona class to control a scene persona
 * @public
 */
export class Persona {
  protected _scene: Scene;
  protected _personaId: PersonaId;

  private _onConversationResultEvent: SmEvent;
  private _onSpeechMarkerEvent: SmEvent;

  /**
   * @param scene - The scene the persona resides in
   * @param personaId - The id of the persona as received in a state callback
   */
  constructor(scene: Scene, personaId: PersonaId) {
    this._scene = scene;
    this._personaId = personaId;

    if (!this._scene.onConversationResultEvents[personaId]) {
      this._scene.onConversationResultEvents[personaId] = new SmEvent();
    }

    // /** Event which will be triggered whenever conversation results are received. Use
    //   *  onConversationResultEvents.addListener() to register a callback for this event.
    //   *  The single parameter to the callback will be an object with the fields:
    //   *    - **status**        - Status code
    //   *    - **errorMessage**  - Optional error strubg
    //   *    - **personaId**     - Numeric ID of Persona
    //   *    - **output:**
    //   *      - **text**        - Textual response from the converstation backend
    //   *    - **context**       - Dictionary of public conversation variables
    //   *    - **provider:**
    //   *      - **kind**        - Conversation backend name, eg "watson"
    //   *      - **meta**        - Conversation backend specific metadata
    //   */
    this._onConversationResultEvent =
      this._scene.onConversationResultEvents[personaId];

    if (!this._scene.onSpeechMarkerEvents[personaId]) {
      this._scene.onSpeechMarkerEvents[personaId] = new SmEvent();
    }

    // /** Event which will be triggered whenever a speech marker is reached. Use
    //   * onSpeechMarkerEvents.addListener() to register a callback for this event.
    //   * A speech marker is triggered using the format @marker(markername, param0,... paramn)
    //   * in speech. There are also other verbs which establish speech markers.
    //   * Eg @showcards(cardA, cardB). When using a speech marker with Watson Assistant,
    //   * the @ symbol must be escaped with a backslash, as follows:
    //   * \@marker(markername, param0,... paramn)
    //   * The single parameter to the callback will be an object with the fields:
    //   *    - **personaId**     - Numeric ID of Persona
    //   *    - **name**          - Kind of speech marker, eg, "showcards", "hidecards"
    //   *    - **arguements**    - Marker specific arguements.
    //   */
    this._onSpeechMarkerEvent = this._scene.onSpeechMarkerEvents[personaId];
  }

  /**
   * Start speaking the given text
   * @param text - The text to speak
   * @param context - The context included in the transcript
   * @param optionalArgs - Optional start speaking arguments or null
   */
  public startSpeaking(
    text: string,
    context: string | null = null,
    optionalArgs: StartSpeakingOptionalArgs | null = null
  ): Promise<any> {
    const body: StartSpeakingRequestBody = {
      personaId: this._personaId,
      text,
    };
    if (context) {
      body.context = context;
    }
    if (optionalArgs) {
      body.optionalArgs = optionalArgs;
    }
    return this._scene.sendRequest('startSpeaking', body);
  }

  /**
   * Stop speaking
   */
  public stopSpeaking(): Promise<any> {
    const body: PersonaRequestBody = {
      personaId: this._personaId,
    };
    return this._scene.sendRequest('stopSpeaking', body);
  }

  /**
   * Send a chat message to conversation
   * @param text - Text to send to conversation
   * @param variables - Variables to send to the conversation provider
   * @param optionalArgs - Optional arguments object (none currently supported)
   */
  public conversationSend(
    text: string,
    variables: VariablesModel,
    optionalArgs: Record<string, unknown>
  ): Promise<any> {
    const body: ConversationSendRequestBody = {
      personaId: this._personaId,
      text,
      variables,
      optionalArgs,
    };
    return this._scene.sendRequest('conversationSend', body);
  }

  /**
   * Set variables that will be applied on the next conversation request
   * triggered by speech to text or a conversationSend()
   * @param variables - Variables to send to the conversation provider
   */
  public conversationSetVariables(variables: VariablesModel): Promise<any> {
    const body: ConversationSetVariablesRequestBody = {
      personaId: this._personaId,
      variables,
    };
    return this._scene.sendRequest('conversationSetVariables', body);
  }

  /**
   * Get variables will return the current conversation variable values
   * in the promise completion.
   * Completion/promise receives an object with member 'variables'.
   */
  public conversationGetVariables(): Promise<any> {
    const body: PersonaRequestBody = {
      personaId: this._personaId,
    };
    return this._scene.sendRequest('conversationGetVariables', body);
  }

  /**
   * Cut or animate to a named camera position, with support for camera adjustment.
   * @param cameraName - Named camera position. The currently supported option is "CloseUp".
   * @param time - Time in seconds for the animation to run. 0 indicates a cut.
   * @param orbitDegX - Degrees of horizontal rotation around implicit orbit point of camera position (typically the middle of the Persona's head)
   * @param orbitDegY - Degrees of vertical rotation around implicit orbit point of camera position (typically the middle of the Persona's head)
   * @param panDeg - Degrees of camera pan. Orbital adjustment is applied before pan adjustment.
   * @param tiltDeg - Degrees of camera tilt. Orbital adjustment is applied before tilt adjustment.
   */
  public animateToNamedCameraWithOrbitPan(
    cameraName: string,
    time: number,
    orbitDegX: number,
    orbitDegY: number,
    panDeg: number,
    tiltDeg: number
  ): Promise<VariablesModel> {
    const body: AnimateToNamedCameraRequestBody = {
      personaId: this._personaId,
      cameraName,
      time,
      orbitDegX,
      orbitDegY,
      panDeg,
      tiltDeg,
    };
    return this._scene.sendRequest('animateToNamedCamera', body);
  }

  /**
   * Play an animation.
   * @internal
   * @param animation - Structured animation data.
   */
  public playAnimation(animation: AnimationModel): Promise<VariablesModel> {
    const body: PlayAnimationRequestBody = {
      personaId: this._personaId,
      animation,
    };
    return this._scene.sendRequest('playAnimation', body);
  }

  /**
   * Get & Set bl variables.
   * @internal
   */
  public getVariables(
    names: string[],
    errorTolerant = false,
    format = ''
  ): Promise<VariablesModel> {
    const body: GetVariablesRequestBody = {
      personaId: this._personaId,
      names,
      errorTolerant,
      format,
    };
    return this._scene.sendRequest('getVariables', body);
  }

  /**
   * @internal
   */
  public setVariables(variables: VariablesModel): Promise<any> {
    const body: SetVariablesRequestBody = {
      personaId: this._personaId,
      Variables: variables,
    };
    return this._scene.sendRequest('setVariables', body);
  }

  /**
   * @internal
   */
  public setVariablesOneway(variables: VariablesModel): void {
    const body: SetVariablesRequestBody = {
      personaId: this._personaId,
      Variables: variables,
    };
    this._scene.sendOnewaySceneRequest('setVariables', body);
  }

  /**
   * Get bl variables list.
   * @internal
   */
  public getVariablesList(): Promise<any> {
    const body: GetVariablesListRequestBody = {
      personaId: this._personaId,
    };
    return this._scene.sendRequest('getVariablesList', body);
  }

  /**
   * Get model bl variables list.
   * @internal
   */
  public getModelVariablesList(modelName: string): Promise<any> {
    const body: GetModelVariablesListRequestBody = {
      personaId: this._personaId,
      Models: modelName,
    };
    return this._scene.sendRequest('getModelVariablesList', body);
  }

  /**
   * Get model children.
   * @internal
   */
  public getModelChildren(modelName: string): Promise<any> {
    const body: GetModelChildrenRequestBody = {
      personaId: this._personaId,
      Models: modelName,
    };
    return this._scene.sendRequest('getModelChildren', body);
  }

  /**
   * Get model list by snippet.
   * @internal
   */
  public getModelFilterSearchResult(modelName: string): Promise<any> {
    const body: GetModelFilterSearchResultRequestBody = {
      personaId: this._personaId,
      Models: modelName,
    };
    return this._scene.sendRequest('getModelFilterSearchResult', body);
  }

  /**
   * Get model variable list by snippet.
   * @internal
   */
  public getModelVariableFilterSearchResult(
    variableName: string
  ): Promise<any> {
    const body: GetModelVariableFilterSearchResultRequestBody = {
      personaId: this._personaId,
      Models: variableName,
    };
    return this._scene.sendRequest('getModelVariableFilterSearchResult', body);
  }

  /**
   * Get connector entries.
   * @internal
   */
  public getConnectorEntries(model: string): Promise<any> {
    const body: any = {
      personaId: this._personaId,
      model,
    };
    return this._scene.sendRequest('getConnectorEntries', body);
  }

  /**
   * Start BL profiling.
   * @internal
   */
  public startBlProfiling(): Promise<any> {
    const body: PersonaRequestBody = {
      personaId: this._personaId,
    };
    return this._scene.sendRequest('startBlProfiling', body);
  }

  /**
   * Stop BL profiling.
   * @internal
   */
  public stopBlProfiling(reverse: boolean): Promise<any> {
    const body: StopBlProfilingRequestBody = {
      personaId: this._personaId,
      reverse,
    };
    return this._scene.sendRequest('stopBlProfiling', body);
  }

  /**
   * Get model hierarchy.
   * @internal
   */
  public getModelHierarchy(model: string): Promise<any> {
    const body: any = {
      personaId: this._personaId,
      model,
    };
    return this._scene.sendRequest('getModelHierarchy', body);
  }

  /**
   * Monitor bl variables.
   * @internal
   */
  public createMonitorSet(
    setName: string,
    variables: Record<string, unknown>
  ): Promise<any> {
    const body = {
      personaId: this._personaId,
      setName: [{ SetName: setName }],
      variables,
    };
    return this._scene.sendRequest('createMonitorSet', body);
  }

  /**
   * @internal
   */
  public removeMonitorSet(setName: string): Promise<any> {
    const body = {
      personaId: this._personaId,
      setName: [{ SetName: setName }],
    };
    return this._scene.sendRequest('removeMonitorSet', body);
  }

  /**
   * @internal
   */
  public addVariableToMonitorSet(
    setName: string,
    variables: Record<string, unknown>
  ): Promise<any> {
    const body = {
      personaId: this._personaId,
      setName: [{ SetName: setName }],
      variables,
    };
    return this._scene.sendRequest('addVariableToMonitorSet', body);
  }

  /**
   * @internal
   */
  public removeVariableFromMonitorSet(
    setName: string,
    variables: Record<string, unknown>
  ): Promise<any> {
    const body = {
      personaId: this._personaId,
      setName: [{ SetName: setName }],
      variables,
    };
    return this._scene.sendRequest('removeVariableFromMonitorSet', body);
  }

  /**
   * @internal
   */
  public renderModel(modelName: string): Promise<any> {
    const body = {
      personaId: this._personaId,
      modelName,
    };
    return this._scene.sendRequest('renderModel', body);
  }

  get onConversationResultEvent(): SmEvent {
    return this._onConversationResultEvent;
  }

  get onSpeechMarkerEvent(): SmEvent {
    return this._onSpeechMarkerEvent;
  }
}
