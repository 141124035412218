/**
 * @module smwebsdk
 */

/*
 * Copyright 2017-2020 Soul Machines Ltd. All Rights Reserved.
 */

import { Deferred } from './Deferred';
import { Features } from './Features';
import { Logger, LogLevel } from './utils/Logger';
import { makeError } from './utils/make-error';
import { WebsocketResponse } from './websocket-message/index';

export interface MessageFunction {
  (message: string): void;
}

export interface WebsocketFunction {
  (message: WebsocketResponse): void;
}

export interface SessionFunction {
  (
    resumeRequested: boolean,
    isResumedSession: boolean,
    server: string,
    sessionId: string
  ): void;
}

/**
 *  LocalSession class
 */
export class LocalSession {
  private _viewport_element: HTMLVideoElement | undefined;
  private _isMicrophoneConnected = false;
  private _isCameraConnected = false;

  private _onConnectedStorage: SessionFunction = (
    resumeRequested: boolean,
    isResumedSession: boolean,
    server: string,
    sessionId: string
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {};
  private _onClose: MessageFunction;
  private _onMessage: WebsocketFunction;
  private _onUserText: MessageFunction;

  private _closed = false;

  private _sessionId: string | undefined;
  private _outgoingQueue: any[] = [];

  private _features: Features;

  private _serverConnection!: WebSocket;

  // Duration that microphone mute is maintained by the web sdk after the persona has
  // finished speaking.  Set to -1 to disable.  Default value is -1 (disabled).
  private _microphoneMuteDelay = -1;

  private _offsetX = 0;
  private _offsetY = 0;

  constructor(
    videoElement: HTMLVideoElement | undefined,
    private logger = new Logger()
  ) {
    if (videoElement) {
      this._viewport_element = videoElement;
    }

    window.SmRuntimeHostReceiveMessage = this.receiveMessage.bind(this);

    if (typeof window.SmRuntimeHostStyleViewportElement === 'function') {
      window.SmRuntimeHostStyleViewportElement(this._viewport_element);
    }

    // owner specifies custom close method
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onClose = (reason: string) => {};
    // owner specifies custom message handler
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onMessage = (message: WebsocketResponse) => {};
    // owner specifies custom rtc user text message handler
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onUserText = (text: string) => {};

    this.sendVideoBounds(0, 0);

    // The initial positioning can take a while. Would be nice to make this more deterministic.
    setTimeout(() => {
      this.sendVideoBounds(0, 0);
    }, 3000);

    this._features = new Features();

    this.log('Local session created!');
  }

  public receiveMessage(raw_text: string) {
    const message = JSON.parse(raw_text);
    this.log(`message received: ${raw_text}`);
    this._onMessage(message);
    if (
      message.name === 'state' &&
      message.category === 'scene' &&
      message.body?.session?.state === 'idle'
    ) {
      this.log('Local session ending - conversationEnded');
      this.close(true, 'conversationEnded');
    }
  }

  set onConnected(sessionFunction: SessionFunction) {
    this._onConnectedStorage = sessionFunction;
  }

  set onClose(closeFunction: MessageFunction) {
    this._onClose = closeFunction;
  }

  set onMessage(messageFunction: WebsocketFunction) {
    this._onMessage = messageFunction;
  }

  set onUserText(userTextFunction: MessageFunction) {
    this._onUserText = userTextFunction;
  }

  /**
   * @deprecated use setLogging(boolean).
   */
  set loggingEnabled(enable: boolean) {
    this.logger.log(
      'warn',
      'loggingEnabled is deprecated and will be removed in a future version. Please use setLogging(boolean)'
    );
    this.logger.enableLogging(enable);
  }

  get loggingEnabled(): boolean {
    return this.logger.isEnabled;
  }

  public setMinLogLevel(level: LogLevel) {
    this.logger.setMinLogLevel(level);
  }

  public setLogging(enable: boolean) {
    this.logger.enableLogging(enable);
  }

  public log(text: string): void {
    this.logger.log('log', text);
  }

  public sendVideoBounds(widthIgnored: number, heightIgnored: number) {
    // We need to defer the update very slightly to give the browser time to reflow,
    // otherwise we get out of date values for width, height etc:
    setTimeout(() => {
      // Brute-force method for getting pos and dimensions, as
      // getBoundingClientRect seems to be unreliable (sometimes
      // returning zeroes for left and right):
      let el: HTMLVideoElement | undefined = this._viewport_element;
      if (el) {
        const view = document.defaultView || window;
        const width = parseInt(<string>view.getComputedStyle(el).width, 10);
        const height = parseInt(<string>view.getComputedStyle(el).height, 10);

        this._offsetX = 0;
        this._offsetY = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
          this._offsetX += el.offsetLeft - el.scrollLeft;
          this._offsetY += el.offsetTop - el.scrollTop;
          el = <HTMLVideoElement>el.offsetParent;
        }

        if (document.documentElement) {
          const x_off = document.documentElement.scrollLeft;
          const y_off = document.documentElement.scrollTop;

          this._offsetX -= x_off;
          this._offsetY -= y_off;
        }

        this.log(
          `Updating bounds: x =  ${this._offsetX} , y = ${this._offsetY}', w = ${width}, h = ${height}`
        );

        // update bounds
        const top = this._offsetY;
        const left = this._offsetX;
        const bottom = this._offsetY + height;
        const right = this._offsetX + width;

        const payload = {
          name: 'videoBounds',
          body: { top, left, bottom, right },
          category: 'local',
          kind: 'event',
        };
        this.sendMessage(payload);
      }
    }, 0);
  }

  private hideVideo() {
    const top = 0;
    const left = 0;
    const bottom = 0;
    const right = 0;

    const payload = {
      name: 'videoBounds',
      body: { top, left, bottom, right },
      category: 'local',
      kind: 'event',
    };
    this.sendMessage(payload);
  }

  public sendRtcEvent(name: string, body: any) {
    // NOOP: Stuff for compatibility with Session in Scene
  }

  public async connect(): Promise<string | undefined | any> {
    const deferred = new Deferred<any>();

    this.log('Local session connecting!');
    this._closed = false;

    const result = await this._features.detectWebRTCFeatures();
    this._closed = false;
    this._sessionId = undefined;
    this._isMicrophoneConnected = result.hasMicrophone;
    this._isCameraConnected = result.hasCamera;

    if (typeof window.local_websocket_port === 'number') {
      this._serverConnection = new WebSocket(
        'ws://localhost:' + window.local_websocket_port
      );
      this.log('websocket open');

      this._serverConnection.onmessage = (msg: MessageEvent) => {
        this.gotMessageFromServer(msg);
      };

      this._serverConnection.onerror = (event) => {
        if (deferred.isPending()) {
          deferred.reject(
            makeError('websocket failed', 'serverConnectionFailed')
          );
        }
      };

      this._serverConnection.onopen = (event: Event) => {
        // disable SmRuntimeHostReceiveMessage
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        window.SmRuntimeHostReceiveMessage = () => {};
        this.log('Local session connected!');

        // send out messages in queue
        for (let i = 0; i < this._outgoingQueue.length; i++) {
          this._serverConnection.send(JSON.stringify(this._outgoingQueue[i]));
          this.logger.log(
            'log',
            'SmLocalSession.prototype.sendMessage, forwarding message to Web Socket: ' +
              this._outgoingQueue[i]
          );
        }
        this._outgoingQueue = [];

        if (deferred.isPending()) {
          deferred.resolve();
        }
      };

      this._serverConnection.onclose = (event: CloseEvent) => {
        this.logger.log(
          'log',
          `websocket closed: code(${event.code}), reason(${event.reason}), clean(${event.wasClean})`
        );
        if (!deferred.isRejected) {
          this.close(false, 'normal');
        }
      };
    } else {
      this.log('local_websocket_port not found! Failed to create WebSocket');

      if (deferred.isPending()) {
        deferred.reject(
          makeError('websocket failed', 'local_websocket_port not found')
        );
      }
    }

    const payload = {
      name: 'startSession',
      body: {},
      category: 'scene',
      kind: 'request',
    };
    this.sendMessage(payload);
    return deferred.promise;
  }

  private gotMessageFromServer(websocket_message: MessageEvent): void {
    const raw_text = websocket_message.data;
    const message = JSON.parse(raw_text);

    const category = message.category;
    const name = message.name;
    const body = message.body;

    if (category !== 'webrtc') {
      // forward on non-webrtc messages (e.g. scene)
      this._onMessage(message);
    } else if (name === 'close') {
      this.close(false, body.reason);
    }

    if (
      name === 'state' &&
      category === 'scene' &&
      body.session !== null &&
      body.session !== undefined &&
      body.session.state === 'idle'
    ) {
      this.log('Local session ending due to server idle message');
      this.close(true, 'conversationEnded');
    }
  }

  public sendMessage(message: any): void {
    const msg = JSON.stringify(message);
    if (
      this._serverConnection &&
      this._serverConnection.readyState === WebSocket.OPEN
    ) {
      this._serverConnection.send(msg);
      this.log(
        `SmLocalSession.prototype.sendMessage, forwarding message to Web Socket: ${msg}`
      );
    } else {
      this._outgoingQueue.push(message);
    }
  }

  public sendUserText(text: string): void {
    this.logger.log(
      'log',
      'SmLocalSession.prototype.sendUserText, discarding text: ' + text
    );
  }

  public close(sendRtcClose = true, reason = 'normal'): void {
    if (this._closed) {
      return;
    }

    this._closed = true;
    this._onClose(reason);
    this._isMicrophoneConnected = false;
    this._isCameraConnected = false;

    this.hideVideo();

    if (this._serverConnection) {
      this.log('closing server connection');
      const normalClosureCode = 1000;
      this._serverConnection.close(normalClosureCode, reason);
    }
  }

  get peerConnection(): RTCPeerConnection | null {
    return null;
  }

  get userMediaStream(): MediaStream | null {
    return null;
  }

  get serverConnection(): WebSocket {
    return this._serverConnection;
  }

  get sessionId(): string | undefined {
    return this._sessionId;
  }

  get isMicrophoneConnected(): boolean {
    return this._isMicrophoneConnected;
  }

  get isCameraConnected(): boolean {
    return this._isCameraConnected;
  }

  get features(): Features {
    return this._features;
  }

  get microphoneMuteDelay(): number {
    return this._microphoneMuteDelay;
  }

  set microphoneMuted(mute: boolean) {
    // todo - RuntimeHost does not yet support this,
    //        currently only needed in webrtc sessions and tests
    if (typeof window.SmRuntimeHostMuteMicrophone === 'function') {
      window.SmRuntimeHostMuteMicrophone(mute);
    }
  }

  get microphoneMuted(): boolean {
    // todo - RuntimeHost does not yet support this,
    //        currently only needed in webrtc sessions and tests
    if (typeof window.SmRuntimeHostIsMicrophoneMuted === 'function') {
      return window.SmRuntimeHostIsMicrophoneMuted();
    }

    return false;
  }

  get offsetX(): number {
    return this._offsetX;
  }

  get offsetY(): number {
    return this._offsetY;
  }

  isMicrophoneActive(): boolean {
    return this.isMicrophoneConnected && !this.microphoneMuted;
  }

  isCameraActive(): boolean {
    return this.isCameraConnected;
  }

  async setMediaDeviceActive({
    microphone,
    camera,
  }: {
    microphone?: boolean;
    camera?: boolean;
  }): Promise<void> {
    throw makeError(
      'setMediaDeviceActive not supported on LocalSession',
      'notSupported'
    );
  }
}
