import { Scene } from './Scene';
import { NLPIntent } from './types/scene';
import { ConversationSendRequestBody } from './websocket-message/scene';

export class MetadataSender {
  private _previousUrl = window.location.href;
  private _observer: MutationObserver;

  constructor(private scene: Scene) {
    this._observer = new MutationObserver(() => this.observeDocumentChanges());
  }

  public observeUrlChanges() {
    this._observer.observe(document, { subtree: true, childList: true });
  }

  public disconnect() {
    this._observer.disconnect();
  }

  public send() {
    const conversationVariables: ConversationSendRequestBody = {
      personaId: this.scene.currentPersonaId,
      text: NLPIntent.PAGE_METADATA,
      variables: {
        pageUrl: window.location.href.split('?')[0],
      },
      optionalArgs: {},
    };

    this.scene.sendRequest('conversationSend', conversationVariables);
  }

  private observeDocumentChanges() {
    if (window.location.href !== this._previousUrl) {
      // Update url to current one
      this._previousUrl = window.location.href;
      // Send event
      this.send();
    }
  }
}
