import { WebsocketResponse } from '../WebsocketResponse';
import { WebsocketCategory } from '../enums/WebsocketCategory';
import { DemoModeResponseBody } from './response-body/DemoModeResponseBody';
import { RecognizeResultsResponseBody } from './response-body/RecognizeResultsResponseBody';
import { ConversationResultResponseBody } from './response-body/ConversationResultResponseBody';
import { SpeechMarkerResponseBody } from './response-body/SpeechMarker';
import { StateResponseBody } from './response-body/StateResponseBody';

/**
 * @public
 */
export type SceneResponseBody =
  | DemoModeResponseBody
  | RecognizeResultsResponseBody
  | ConversationResultResponseBody
  | SpeechMarkerResponseBody
  | StateResponseBody;

/**
 * @public
 */
export interface SceneResponse extends WebsocketResponse {
  body: SceneResponseBody;
  category: WebsocketCategory.Scene;
  status: number; // status value where >= 0 is success, < 0 is failure
  transaction?: string;
}
