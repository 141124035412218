import { LogLevel } from '../utils/Logger';
export * from '../websocket-message/scene';
export * from '../models/Conversation';

/**
 * Represents the different combinations of User Media that are supported
 * @public
 */
export enum UserMedia {
  None,
  Microphone,
  MicrophoneAndCamera,
  Camera,
}

/**
 * @public
 */
export enum NLPIntent {
  PAGE_METADATA = 'PAGE_METADATA',
}

/**
 * @public
 */
export interface RetryOptions {
  maxRetries?: number;
  delayMs?: number;
}

/**
 * @public
 */
export type LoggingConfig = Record<
  'session' | 'contentAwareness',
  {
    minLogLevel?: LogLevel;
    enabled?: boolean;
  }
>;

/**
 * @public
 */
export type MediaDeviceOptions = {
  microphone?: boolean;
  camera?: boolean;
};
