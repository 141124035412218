const logLevels = ['debug', 'log', 'warn', 'error'] as const;

/**
 * @public
 */
export type LogLevel = typeof logLevels[number];

export class Logger {
  private availableLogLevels: LogLevel[] = [];
  private _minLogLevel: LogLevel = 'log';

  constructor(minLogLevel: LogLevel = 'log', public isEnabled = true) {
    this.setMinLogLevel(minLogLevel);
  }

  log(type: LogLevel, ...args: any) {
    if (this.isEnabled && this.availableLogLevels.includes(type)) {
      console[type](...args);
    }
  }

  enableLogging(enable: boolean) {
    this.isEnabled = enable;
  }

  getMinLogLevel() {
    return this._minLogLevel;
  }

  setMinLogLevel(level: LogLevel) {
    const index = logLevels.indexOf(level);

    this._minLogLevel = level;
    this.availableLogLevels = logLevels.slice(index);
  }
}
