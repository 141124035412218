/**
 * @public
 */
export class SceneResponseError extends Error {
  requestName = '';
  status = 0;
  responseBody: any;

  get message() {
    return `Scene response failed, status: ${this.status}`;
  }
}
