/**
 * @public
 */
export enum ConnectionStateTypes {
  Disconnected = 'Disconnected',
  //websocket open - searching for an available DP scene, may require queuing
  SearchingForDigitalPerson = 'SearchingForDigitalPerson',
  //established - scene is available/found, downloading/preparing DP assets on the server
  DownloadingAssets = 'DownloadingAssets',
  //accepted - DP is starting / forming webrtc connection
  ConnectingToDigitalPerson = 'ConnectingToDigitalPerson',
  //connected - DP is started and ready the webrtc session has connected
  Connected = 'Connected',
}

/**
 * @public
 */
export interface ConnectionStateData {
  name: ConnectionStateTypes;
  currentStep: number;
  totalSteps: number;
  percentageLoaded: number;
}
