/*
 * Copyright 2021 Soul Machines Ltd. All Rights Reserved.
 */

import { ResizeObserver } from '@juggle/resize-observer';
import { Scene } from './Scene';
import { ContentAwarenessObjectModel } from './models';
import { UpdateContentAwarenessRequestBody } from './websocket-message/scene';
import allImagesLoaded from './utils/allImagesLoaded';
import { debouncedFunction } from './utils/debounce';
import { Logger, LogLevel } from './utils/Logger';

/**
 * ContentAwareness class
 *
 * An instance of this class is used to enable CUE behaviors in the digital human.
 * This is achived by measuring tagged HTML elements and sending their coordinates back to the server
 *
 * See documentation on GitHub for further reference on how to use this API
 * https://github.com/soulmachines/smwebsdk/blob/cue-content-awareness-api/guide/content-awareness.md
 *
 * @public
 */
export class ContentAwareness {
  // Data Attribute Strings
  private readonly VIDEO_FRAME_STR = 'data-sm-video';
  private readonly VIDEO_FRAME_STR_BRACKETED = `[${this.VIDEO_FRAME_STR}]`;
  private readonly CONTENT_STR = 'data-sm-content';
  private readonly CONTENT_STR_BRACKETED = `[${this.CONTENT_STR}]`;
  private readonly CUE_ATTRIBUTES = [this.VIDEO_FRAME_STR, this.CONTENT_STR];
  private readonly CUE_ATTRIBUTES_BRACKETED = [
    this.VIDEO_FRAME_STR_BRACKETED,
    this.CONTENT_STR_BRACKETED,
  ].join();
  private readonly RESIZE_OBSERVER_BOX_OPTIONS = 'border-box';

  // Observers
  private mutationObserver: MutationObserver;
  public resizeObserver: ResizeObserver;

  private callMeasure = false;

  public contentCollection: Record<string, Element> = {};
  public videoFrame: Element | null = null;

  // Callbacks
  public debouncedMeasure: CallableFunction;

  constructor(
    private scene: Scene,
    public debounceTime = 300,
    private logger = new Logger()
  ) {
    this.debouncedMeasure = debouncedFunction(
      () => this.measureInternal(),
      debounceTime
    );

    this.resizeObserver = new ResizeObserver(() => this.measureDebounced());
    this.getInitialElements();
    this.mutationObserver = new MutationObserver((mutations) =>
      this.mutationCallback(mutations)
    );
    this.setupEventListeners();
    this.observeMutations();
    this.measureInternal();
  }

  /**
   * Check if the content awareness logging is enabled.
   *
   * @returns Returns true if the content awareness logging is enabled otherwise false.
   */
  public isLoggingEnabled(): boolean {
    return this.logger.isEnabled;
  }

  /**
   * Enable/disable content awareness logging
   * @param enable - set true to enable content awareness log, false to disable
   */
  public setLogging(enable: boolean) {
    this.logger.enableLogging(enable);
  }

  /**
   * Check minimal log level of content awareness.
   *
   * @returns Returns minimal log setting of content awareness, type is LogLevel.
   */
  public getMinLogLevel() {
    return this.logger.getMinLogLevel();
  }

  /**
   * Set minimal log level of  content awareness logging.
   * @param level - use LogLevel type to set minimal log level of  content awareness logging
   */
  public setMinLogLevel(level: LogLevel) {
    this.logger.setMinLogLevel(level);
  }

  public setupEventListeners() {
    window.addEventListener('resize', () => this.measureDebounced());
  }

  /**
   * Get initial elements, future elements will be added via mutation observer
   */
  private getInitialElements() {
    const videoEl = document.querySelector(this.VIDEO_FRAME_STR_BRACKETED);
    const contentElements = document.querySelectorAll(
      this.CONTENT_STR_BRACKETED
    );

    this.trackVideoElement(videoEl);

    Array.from(contentElements).map((element) =>
      this.trackContentElement(element)
    );
  }

  /**
   * Start watching for changes in the DOM that are relevant to
   * ContentAwareness object tracking.
   * @returns The ContentAwareness MutationObserver used for all content
   */
  private observeMutations() {
    const watchNode: Node = document.documentElement || document.body; // Target node of DOM to watch

    this.mutationObserver.observe(watchNode, {
      attributeFilter: this.CUE_ATTRIBUTES, // Restrict monitoring to these attributes.
      attributeOldValue: true, // Stores old value
      childList: true, // Trigger on addition / removal of child elements
      subtree: true, // Monitor elements in child directories
    });
  }

  /**
   * Publicly accessible function to disconnect observers and event listeners
   */
  public disconnect(): void {
    // Disconnect observers
    this.mutationObserver.disconnect();
    this.resizeObserver.disconnect();

    // Remove event listeners
    window.removeEventListener('resize', () => this.measureDebounced());

    // Reset scene
    this.scene.contentAwareness = undefined;
  }

  /**
   * Publicly accessible function to reconnect observers and event listeners
   */
  public reconnect(): void {
    // Restore the link between scene and ca
    this.scene.contentAwareness = this;

    this.observeMutations();

    this.setupEventListeners();

    this.measure();
  }

  /**
   * Publicly accessible function to trigger measurement of CUE-relevant elements in the DOM
   * and send an updateContentAwareness message
   */
  public measure(): void {
    this.measureInternal();
  }

  public measureDebounced() {
    this.debouncedMeasure();
  }

  /**
   * measures data-sm-video and data-sm-content HTML Elements
   *
   * This is automatically called in simple scenarios but can be manually called
   * if the dev knows an important element has changed
   *
   * See documentation on GitHub for further reference on how to use this API
   * https://github.com/soulmachines/smwebsdk/blob/cue-content-awareness-api/guide/content-awareness.md
   *
   * Console logs the sent message on success or an error on failure
   */
  private measureInternal(): void {
    if (!this.scene.isConnected()) {
      this.logger.log(
        'error',
        'ContentAwareness: Scene does not exist or is not connected yet'
      );

      return;
    }

    const windowSize = this.measureWindow();
    const videoFrame = this.measureVideoFrame();
    const contentCollection = this.measureContent();

    if (windowSize && videoFrame && contentCollection) {
      const contentAwarenessMessage = this.buildUpdateContentAwarenessRequest(
        windowSize.innerWidth,
        windowSize.innerHeight,
        videoFrame,
        contentCollection
      );

      this.scene.sendRequest('updateContentAwareness', contentAwarenessMessage);
    }
  }

  /**
   * measure elements tagged with data-videoFrame
   * @returns a ContentAwarenessObjectModel filled with
   * videoFrame coordinates on success. returns null on failure
   */
  private measureVideoFrame(): ContentAwarenessObjectModel | null {
    if (!this.videoFrame) {
      this.logger.log(
        'warn',
        'ContentAwareness: Unable to find a video element'
      );
      return null;
    }

    const videoRect = this.videoFrame.getBoundingClientRect();

    if (this.invalidDimensions(videoRect)) {
      this.logger.log(
        'warn',
        'ContentAwareness: Video has a zero width and height'
      );
      return null;
    }

    return {
      x1: Math.round(videoRect.left),
      x2: Math.round(videoRect.right),
      y1: Math.round(videoRect.top),
      y2: Math.round(videoRect.bottom),
    };
  }

  /**
   * measure elements tagged with data-content
   * @returns a ContentAwarenessObjectModel array filled with the coordinates and
   * ids of content tagged with the data-sm-content attribute
   */
  private measureContent() {
    const validContent: ContentAwarenessObjectModel[] = [];

    Object.keys(this.contentCollection).map((key) => {
      const contentElement = this.contentCollection[key];
      const contentRect = contentElement.getBoundingClientRect();

      if (this.invalidDimensions(contentRect)) {
        this.logger.log(
          'warn',
          `ContentAwareness: Element '${key}' has a zero width and height`
        );
      }

      if (this.invalidContent(contentRect)) {
        this.logger.log(
          'warn',
          `ContentAwareness: Element '${key}' is not being tracked`
        );
        // Remove id from content collection but keep observers incase it changes
        delete this.contentCollection[key];
        return;
      }

      validContent.push({
        id: key,
        x1: Math.round(contentRect.left),
        x2: Math.round(contentRect.right),
        y1: Math.round(contentRect.top),
        y2: Math.round(contentRect.bottom),
      });
    });

    return validContent;
  }

  /**
   * Preliminary indication of if content dimensions are valid or not.
   * Checks if the length and height are zero
   * @param contentRect - the DOMRect to check
   * @returns a bool indicating validity
   */
  private invalidDimensions(contentRect: DOMRect): boolean {
    return contentRect.width === 0 && contentRect.height === 0;
  }

  /**
   * Preliminary indication of if content is valid or not.
   * Checks if the coordinates are non zero
   * @param contentRect - the DOMRect to check
   * @returns a bool indicating validity
   */
  private invalidContent(contentRect: DOMRect): boolean {
    return (
      contentRect.top === 0 &&
      contentRect.bottom === 0 &&
      contentRect.right === 0 &&
      contentRect.left === 0
    );
  }

  /**
   * measure the browser window
   * @returns an object containing the window height (innerHeight) and window width (innerWidth)
   */
  private measureWindow(): { innerHeight: number; innerWidth: number } {
    return {
      innerHeight: Math.round(window.innerHeight),
      innerWidth: Math.round(window.innerWidth),
    };
  }

  /**
   * Builds the required UpdateContentAwareness message that gets sent to the server
   *
   * @param viewWidth - the width of the browser window
   * @param viewHeight - the height of the browser window
   * @param videoFrame - an object containing the coordinates of the video element in which the persona exists
   * @param content - an array of objects containing the coordinates of the content elements the persona should be aware of
   * @returns - UpdateContentAwarenessRequestBody the message body to send
   *
   * The return value from this function should be passed to
   * scene.sendRequest('updateContentAwareness', body)
   */
  public buildUpdateContentAwarenessRequest(
    viewWidth: number,
    viewHeight: number,
    videoFrame: ContentAwarenessObjectModel,
    content: Array<ContentAwarenessObjectModel>
  ): UpdateContentAwarenessRequestBody {
    return {
      viewWidth,
      viewHeight,
      videoFrame,
      content,
    };
  }

  private trackVideoElement(element: Element | null) {
    if (!element) {
      return;
    }
    if (this.videoFrame) {
      this.logger.log(
        'warn',
        'ContentAwareness: Already observing a video element, switching to new video element'
      );

      this.untrackVideoElement(this.videoFrame);
    }

    this.videoFrame = element;
    this.resizeObserver.observe(this.videoFrame, {
      box: this.RESIZE_OBSERVER_BOX_OPTIONS,
    });
  }

  private trackContentElement(element: Element): boolean {
    const id = element.getAttribute(this.CONTENT_STR);
    if (id) {
      this.contentCollection[id] = element;
      this.resizeObserver.observe(element, {
        box: this.RESIZE_OBSERVER_BOX_OPTIONS,
      });
      return true;
    }
    return false;
  }

  private untrackContentElement(element: Element) {
    const id = element.getAttribute(this.CONTENT_STR) as string;
    const trackedElement = this.contentCollection[id];

    // only untrack the element if the element we have stored
    // against that id is the same one being requested for removal.
    // this allows for showing/hiding of elements that use the same id.
    if (element === trackedElement) {
      delete this.contentCollection[id];
      this.resizeObserver.unobserve(element);
    }
  }

  private untrackVideoElement(element: Element) {
    // Only clear the videoFrame element if its the same as the currently track video element.
    // A mismatch can occur when adding/removing different video elements. If a new video element appears we'll switch and track that.
    // If the remove event occurs after the switch we'll ignore it, as we don't want to untrack the newly added element
    if (element === this.videoFrame) {
      this.videoFrame = null;
    }

    // Always unobserve the removed element
    this.resizeObserver.unobserve(element);
  }

  /**
   * Takes an array of MutationRecords and measures the ones marked with content awareness attributes
   * @param mutations - an array of MutationRecords to check and measure
   */
  public mutationCallback(mutations: readonly MutationRecord[]): void {
    let imagesAdded = false;
    this.callMeasure = false; // reset callMeasure

    for (let i = 0; i < mutations.length; ++i) {
      switch (mutations[i].type) {
        case 'childList': {
          if (mutations[i].target.nodeType !== Node.ELEMENT_NODE) {
            break;
          }

          this.untrackRemovedNodeWithCUE(mutations[i].removedNodes); // Unobserve and stop tracking removed elements
          this.trackAddedNodeWithCUE(mutations[i].addedNodes); // Start tracking elements added elements

          for (let j = 0; j < mutations[i].addedNodes.length; j++) {
            try {
              const element = mutations[i].addedNodes[j] as Element;

              if (!element.hasAttribute) {
                // node is not an element, do not continue processing it
                continue;
              }

              const isImage = element.tagName === 'IMG';
              const containsImages = !!element.querySelector('img');

              imagesAdded = isImage || containsImages;
              if (imagesAdded) {
                break;
              }
            } catch (err) {
              this.logger.log(
                'warn',
                'ContentAwareness: Failed to track non-element node',
                mutations[i].addedNodes[j]
              );
            }
          }
          break;
        }
        case 'attributes': {
          if (mutations[i].target.nodeType !== Node.ELEMENT_NODE) {
            break;
          }

          try {
            const element = mutations[i].target as Element;
            const attr = mutations[i].attributeName as string;

            if (attr === this.VIDEO_FRAME_STR) {
              if (element.hasAttribute(attr)) {
                this.trackVideoElement(element);
                this.callMeasure = true;
              } else if (this.videoFrame) {
                this.untrackVideoElement(element);
              }
            } else if (attr === this.CONTENT_STR) {
              const newValue = element.getAttribute(attr) as string;
              const oldValue = mutations[i].oldValue as string;

              if (oldValue) {
                // data-sm-content attribute was changed and must be removed
                this.resizeObserver.unobserve(this.contentCollection[oldValue]);
                delete this.contentCollection[oldValue];
              }

              if (newValue) {
                // data-sm-content attribute value was changed/added and now must be added to the list
                this.contentCollection[newValue] = element;
                this.resizeObserver.observe(element, {
                  box: this.RESIZE_OBSERVER_BOX_OPTIONS,
                });
              }

              this.callMeasure = true;
            }

            break;
          } catch (err) {
            this.logger.log(
              'warn',
              'ContentAwareness: Failed to track non-element node',
              mutations[i].target
            );
          }
        }
      }
    }

    if (this.callMeasure) {
      if (imagesAdded) {
        // Wait for all images to be loaded then remeasure
        allImagesLoaded().then(() => {
          this.measureDebounced();
        });
      } else {
        this.measureDebounced();
      }
    }
  }

  private trackAddedNodeWithCUE(mutations: NodeList): void {
    mutations.forEach((node) => {
      try {
        const element = node as Element;

        if (!element.hasAttribute) {
          // node is not an element, do not continue processing it
          return;
        }

        // check top level node for cue attributes
        if (element.hasAttribute(this.VIDEO_FRAME_STR)) {
          this.trackVideoElement(element);
          this.callMeasure = true;
        } else if (element.hasAttribute(this.CONTENT_STR)) {
          this.callMeasure = this.trackContentElement(element);
        }

        // check child nodes for cue attributes
        if (element.querySelector(this.CUE_ATTRIBUTES_BRACKETED) !== null) {
          element
            .querySelectorAll(this.CUE_ATTRIBUTES_BRACKETED)
            .forEach((childElement) => {
              if (childElement.hasAttribute(this.VIDEO_FRAME_STR)) {
                this.trackVideoElement(childElement);
                this.callMeasure = true;
              } else if (childElement.hasAttribute(this.CONTENT_STR)) {
                this.callMeasure = this.trackContentElement(childElement);
              }
            });
        }
      } catch (err) {
        this.logger.log(
          'warn',
          'ContentAwareness: Failed to track non-element node',
          node
        );
      }
    });
  }

  private untrackRemovedNodeWithCUE(mutations: NodeList): void {
    mutations.forEach((node) => {
      try {
        const element = node as Element;

        if (!element.hasAttribute) {
          // node is not an element, do not continue processing it
          return;
        }

        if (element.hasAttribute(this.VIDEO_FRAME_STR)) {
          this.untrackVideoElement(element);
        } else if (element.hasAttribute(this.CONTENT_STR)) {
          this.untrackContentElement(element);
          this.callMeasure = true;
        }

        // check child nodes for cue attributes
        if (element.querySelector(this.CUE_ATTRIBUTES_BRACKETED) !== null) {
          element
            .querySelectorAll(this.CUE_ATTRIBUTES_BRACKETED)
            .forEach((childElement) => {
              if (childElement.hasAttribute(this.VIDEO_FRAME_STR)) {
                this.untrackVideoElement(childElement);
              } else if (childElement.hasAttribute(this.CONTENT_STR)) {
                this.untrackContentElement(childElement);
                this.callMeasure = true;
              }
            });
        }
      } catch (err) {
        this.logger.log(
          'warn',
          'ContentAwareness: Failed to track non-element node',
          node
        );
      }
    });
  }
}
