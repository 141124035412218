/**
 * @module smwebsdk
 */

import { ConversationStateTypes } from './enums/ConversationStateTypes';
import { SmEvent } from './SmEvent';
import { SpeechState } from './websocket-message/enums/SpeechState';
import { RecognizeResultsResponseBody } from './websocket-message/scene';
import { StateResponseBody } from './websocket-message/scene/response-body/StateResponseBody';
import { Logger } from './utils/Logger';

/**
 * Determines and stores Conversation State.
 *
 * @public
 */
export class ConversationState {
  private _conversationState: ConversationStateTypes =
    ConversationStateTypes.idle;
  private _userActive = false;
  private _onConversationStateUpdated: SmEvent;

  constructor(private logger = new Logger()) {
    this._onConversationStateUpdated = new SmEvent();
  }
  public processStateMessage(responseBody: StateResponseBody) {
    if (
      responseBody &&
      responseBody.persona &&
      Object.keys(responseBody.persona).length > 0
    ) {
      const dpId: string = Object.keys(responseBody.persona)[0];
      if (!this._userActive && responseBody.persona[dpId].speechState) {
        const messageSpeechState: SpeechState = responseBody.persona[dpId]
          .speechState as SpeechState;
        if (messageSpeechState === SpeechState.Idle) {
          this.setSpeechState(ConversationStateTypes.idle);
        } else if (messageSpeechState === SpeechState.Speaking) {
          this.setSpeechState(ConversationStateTypes.dpSpeaking);
        }
      }
    }
  }

  public processRecognizeResultsMessage(
    responseBody: RecognizeResultsResponseBody
  ) {
    this._userActive = true;
    const isErrorMessage = responseBody.status !== 0;
    const isFinal =
      responseBody.results &&
      responseBody.results.some((result) => result.final);
    if (isErrorMessage) {
      const errorMessage =
        typeof responseBody.errorMessage !== 'undefined'
          ? responseBody.errorMessage
          : 'recognizeResults sent back a message with non-zero status but no error message.';
      this.logger.log('error', errorMessage);
    } else if (isFinal) {
      this.setSpeechState(ConversationStateTypes.dpProcessing);
    } else {
      this.setSpeechState(ConversationStateTypes.userSpeaking);
    }
    this._userActive = false;
  }

  private setSpeechState(speechState: ConversationStateTypes) {
    if (this._conversationState !== speechState) {
      this._conversationState = speechState;
      this._onConversationStateUpdated.call(this._conversationState);
    }
  }

  public getSpeechState(): ConversationStateTypes {
    return this._conversationState;
  }

  public get onConversationStateUpdated(): SmEvent {
    return this._onConversationStateUpdated;
  }

  public reset() {
    this._userActive = false;
    this._conversationState = ConversationStateTypes.idle;
    this._onConversationStateUpdated.call(this._conversationState);
  }
}
