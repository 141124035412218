import { MediaDeviceOptions, UserMedia } from '../types/scene';

const convertToUserMedia = (
  options: MediaDeviceOptions | undefined,
  fallbackValue: UserMedia = UserMedia.None
) => {
  if (!options) {
    return fallbackValue;
  }

  if (options.camera && options.microphone) {
    return UserMedia.MicrophoneAndCamera;
  } else if (options.camera) {
    return UserMedia.Camera;
  } else if (options.microphone) {
    return UserMedia.Microphone;
  } else {
    return UserMedia.None;
  }
};

export default convertToUserMedia;
