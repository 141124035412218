import { Tracer } from '@opentelemetry/api';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';

const DEFAULT_URL = 'http://localhost:4318/v1/traces';

export const SmTracerProvider: {
  init: (opts: ProviderInitOptions) => void;
  isInitialized: () => boolean;
  getTracer: () => Tracer;
  defaultUrl: string;
} = (() => {
  let _tracerProvider: WebTracerProvider;
  let _instance: Tracer;
  return {
    init: (opts) => {
      if (_instance) {
        return;
      }
      opts.url = opts.url || DEFAULT_URL;
      opts.webSDKVersion = opts.webSDKVersion || 'unknown';
      _tracerProvider = new WebTracerProvider({
        resource: new Resource({
          [SemanticResourceAttributes.SERVICE_NAME]: 'smwebsdk',
          [SemanticResourceAttributes.SERVICE_VERSION]: opts.webSDKVersion,
        }),
      });
      _tracerProvider.addSpanProcessor(
        new BatchSpanProcessor(
          new OTLPTraceExporter({
            url: opts.url,
            headers: {
              Authorization: 'Bearer ' + opts.jwt,
            },
          })
        )
      );
      _tracerProvider.register();
      _instance = _tracerProvider.getTracer('smwebsdk');
    },
    isInitialized: () => {
      return !!_instance;
    },
    getTracer: () => {
      return _instance;
    },
    defaultUrl: DEFAULT_URL,
  };
})();
Object.freeze(SmTracerProvider);

/**
 * Available configuration options for the tracer provider.
 */
export type ProviderInitOptions = {
  jwt: string;
  url: string | undefined;
  webSDKVersion: string | undefined;
};
