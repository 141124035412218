/**
 * @module smwebsdk
 */

import {
  ConnectionStateData,
  ConnectionStateTypes,
} from './enums/ConnectionStateTypes';
import { SmEvent } from './SmEvent';

const TOTAL_CONNECTION_STEPS = Object.keys(ConnectionStateTypes).length;

/**
 * Determines and stores Connection State.
 *
 * @public
 */
export class ConnectionState {
  private _connectionState: ConnectionStateData = {
    name: ConnectionStateTypes.Disconnected,
    totalSteps: TOTAL_CONNECTION_STEPS,
    currentStep: 0,
    percentageLoaded: 0,
  };

  private _onConnectionStateUpdated: SmEvent;

  constructor() {
    this._onConnectionStateUpdated = new SmEvent();
  }

  public setConnectionState(connectionStateType: ConnectionStateTypes) {
    // Return if state has not changed
    if (this._connectionState.name === connectionStateType) {
      return;
    }

    const currentStep =
      Object.keys(ConnectionStateTypes).indexOf(connectionStateType);

    const data: ConnectionStateData = {
      name: connectionStateType,
      currentStep: currentStep,
      totalSteps: TOTAL_CONNECTION_STEPS,
      percentageLoaded: (100 / (TOTAL_CONNECTION_STEPS - 1)) * currentStep,
    };

    this._connectionState = data;
    this._onConnectionStateUpdated.call(data);
  }

  public getConnectionState(): ConnectionStateData {
    return this._connectionState;
  }

  public get onConnectionStateUpdated(): SmEvent {
    return this._onConnectionStateUpdated;
  }

  public reset() {
    this.setConnectionState(ConnectionStateTypes.Disconnected);
  }
}
