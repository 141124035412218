/**
 * smwebsdk.js creates the global namespace variable _smwebsdk_ to access the API from.
 *
 * ```ts
 * window.smwebsdk
 * ```
 * @module smwebsdk
 * @preferred
 */

/**
 * Copyright 2017-2020 Soul Machines Ltd. All Rights Reserved.
 */

import { Features } from './Features';
import { Persona } from './Persona';
import { Scene } from './Scene';
import { UserMedia as SessionUserMedia } from './types/scene';

export enum userMedia {
  none = SessionUserMedia.None,
  microphone = SessionUserMedia.Microphone,
  microphoneAndCamera = SessionUserMedia.MicrophoneAndCamera,
  camera = SessionUserMedia.Camera,
}

/**
 * SmWebSdk class for legacy compatibility
 * @deprecated - please use the other top level classes such as {@link Scene} or {@link Persona} instead
 */
export class SmWebSdk {
  public Scene = Scene;
  public Persona = Persona;

  // eslint-disable-next-line @typescript-eslint/ban-types
  public DetectCapabilities: Function;
  public userMedia = {
    none: SessionUserMedia.None,
    microphone: SessionUserMedia.Microphone,
    microphoneAndCamera: SessionUserMedia.MicrophoneAndCamera,
    camera: SessionUserMedia.Camera,
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  public setLogging: Function;

  constructor() {
    this.DetectCapabilities = function () {
      return new Features().detectWebRTCFeatures();
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.setLogging = function (value: boolean) {};
  }
}

/**
 * @deprecated - please use the other top level classes such as {@link Scene} or {@link Persona} instead
 * @public
 */
export const smwebsdk = new SmWebSdk();

// This is here purely to coerce the documentation
/**
 * Detect the browser capabilities
 */
export function DetectCapabilities() {
  return new Features().detectWebRTCFeatures();
}

// This is here purely to coerce the documentation
/**
 * Set logging for smbwebsdk to enabled or disabled, defaults to enabled
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function setLogging(value: boolean) {}
