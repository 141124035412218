import { PersonaRequestBody } from './PersonaRequestBody';
import { VariablesModel } from '../../../models/VariablesModel';

/**
 * @public
 */
export interface ConversationSetVariablesRequestBody
  extends PersonaRequestBody {
  variables: VariablesModel;
}
