/**
 * Replace "wss://" at the front of the URL with "https://".
 * @param uri the uri to convert
 * @returns the converted uri
 */
export function convertWssToHttps(uri: string) {
  return uri.replace(/^wss:\/\//, 'https://');
}

/**
 * Removes the path and search parameters from the url.
 * @param url - The url to be sanitized.
 * @returns The sanitized url.
 */
export function getUrlHost(url: string): string | undefined {
  try {
    if (!url) {
      return undefined;
    }
    const outUrl = new URL(url);
    // remove path and query params
    outUrl.pathname = '';
    outUrl.search = '';
    return outUrl.toString();
  } catch (e) {
    return undefined;
  }
}
