/**
 * @module smwebsdk
 */

/*
 * Copyright 2017-2020 Soul Machines Ltd. All Rights Reserved.
 */

/**
 * An event which can have a set of listeners registered against it using addListener().
 * @public
 */
export class SmEvent {
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _callbacks: Function[] = [];

  /**
   * Register a new listener for this event.
   * @param callback - Function called each time the event is triggered.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public addListener(callback: Function): void {
    this._callbacks.push(callback);
  }

  /**
   * Deregister a existing listener for this event.
   * @param callback - Previously registered function to be removed. It is a safe no-op to pass
   * a callback which was never registered against this event.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public removeListener(callback: Function): void {
    const callbackIndex = this._callbacks.indexOf(callback);
    if (callbackIndex > -1) {
      this._callbacks.splice(callbackIndex, 1);
    }
  }

  /**
   * Trigger the event, calling each registered listener, and passing on any parameters.
   */
  public call(...args: any[]) {
    this._callbacks.forEach((callback) => {
      callback.apply(callback, args);
    }, this);
  }
}
