import { ContentCard, ContentCardFormattedData } from './models/Conversation';
import { ConversationResultResponseBody } from './websocket-message/scene';

/**
 * Formats content card data in a consistent structure
 *
 * @public
 */
export class ContentCardFormatter {
  /**
   * Format different NLP content cards into a consistent structure
   */
  format(body: ConversationResultResponseBody): ContentCardFormattedData[] {
    const fullfillments =
      body.provider.meta.dialogflow?.queryResult.fulfillmentMessages;

    const data = fullfillments?.find((fullfillment) =>
      Boolean(fullfillment?.payload)
    );
    if (data) {
      return this.formatLegacyDialogflow(data);
    }
    return this.formatContextData(body.output.context);
  }

  private formatLegacyDialogflow(data: {
    payload: { soulmachines: Record<string, ContentCard> };
  }) {
    const payload = data.payload.soulmachines;
    return Object.keys(payload).map((id) => {
      return {
        id,
        data: {
          id,
          ...this.formatCardData(payload[id]),
        },
      };
    });
  }

  private formatContextData(data: Record<string, string | unknown>) {
    return this.allowedIds(data).map((id) => {
      const cardId = id.replace('public-', '');

      return {
        id: cardId,
        data: {
          id: cardId,
          ...this.formatCardData(data[id]),
        },
      };
    });
  }

  private allowedIds<T>(values: Record<string, T>) {
    return Object.keys(values)
      .filter((k) => /public-/.test(k) === true)
      .filter((k: string) => /\.original/gm.test(k) === false);
  }

  // Data should be JSON or a ContentCard, however, we can attempt to parse
  // unknown values as JSON.
  private formatCardData(data: ContentCard | string | unknown) {
    const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    const { component, type, ...rest } = parsedData;

    return {
      type: type ? type : component,
      ...rest,
    };
  }
}
