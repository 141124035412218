import { ContentCardRawData } from './models/Conversation';
import { SmEvent } from './SmEvent';
import { Logger } from './utils/Logger';
import {
  ConversationResultResponseBody,
  RecognizeResultsResponseBody,
  SpeechMarkerResponseBody,
  StateResponseBody,
} from './websocket-message/scene';
import { SpeechMarkerName } from './websocket-message/scene/response-body/SpeechMarker';
import { ConversationState } from './ConversationState';
import { ContentCardFormatter } from './ContentCardFormatter';

/**
 * Stores content cards and conversation state
 *
 * @public
 */
export class Conversation {
  public cardData: Map<string, ContentCardRawData>;
  public activeCardIds: Set<string>;
  private _onCardChanged: SmEvent = new SmEvent();
  private _autoClearCards = false;

  constructor(
    private logger = new Logger(),
    private conversationState = new ConversationState(),
    private contentCardFormatter = new ContentCardFormatter()
  ) {
    this.cardData = new Map();
    this.activeCardIds = new Set();
  }

  public processStateMessage(message: StateResponseBody) {
    this.conversationState.processStateMessage(message);
  }

  public processRecognizeResultsMessage(message: RecognizeResultsResponseBody) {
    this.conversationState.processRecognizeResultsMessage(message);
  }

  /**
   * A callback function which fires when conversation state changes
   */
  public get onConversationStateUpdated() {
    return this.conversationState.onConversationStateUpdated;
  }

  /**
   * Automatically clear active content cards each conversation turn
   */
  public set autoClearCards(enabled: boolean) {
    this._autoClearCards = enabled;
  }

  /**
   * A callback function which fires when active cards are changed
   */
  public get onCardChanged(): SmEvent {
    return this._onCardChanged;
  }

  get activeCards(): ContentCardRawData[] {
    const activeCardInfo: ContentCardRawData[] = [];
    this.activeCardIds.forEach((id) => {
      const data = this.cardData.get(id);
      if (data) {
        activeCardInfo.push(data);
      } else {
        this.logger.log('error', `card data for ${id} does not exist`);
      }
    });

    return activeCardInfo;
  }

  /**
   * Handles speech marker messages and updates the active card state
   */
  public onSpeechMarker(messageBody: SpeechMarkerResponseBody): void {
    const cardIds = messageBody.arguments;
    if (messageBody.name === SpeechMarkerName.Showcards) {
      this.addActiveCardIds(cardIds);
    } else if (messageBody.name === SpeechMarkerName.Hidecards) {
      this.removeActiveCards(cardIds);
    }
    if (
      // Hide all cards when arg list is empty and hidecards message is received
      cardIds.length === 0 &&
      messageBody.name === SpeechMarkerName.Hidecards
    ) {
      this.clearActiveCards();
    }
  }

  /**
   * Stores content card data contained in conversation result messages
   */
  public onConversationResult(
    messageBody: ConversationResultResponseBody
  ): void {
    const cards = this.contentCardFormatter.format(messageBody);

    if (this._autoClearCards) {
      this.clearActiveCards();
    }

    cards.map(({ id, data }) => {
      this.cardData.set(id, data);
    });
  }

  /**
   * Clears active card ids and data. Emits a card changed event
   */
  public reset() {
    this.clearActiveCards();
    this.cardData.clear();
    this.conversationState.reset();
  }

  /**
   * Clears active card ids. Emits a card changed event
   */
  public clearActiveCards() {
    this.activeCardIds.clear();
    this.onCardChanged.call(this.activeCards);
  }

  private addActiveCardIds(cardIds: string[]) {
    this.activeCardIds = new Set([...this.activeCardIds, ...cardIds]);
    this.onCardChanged.call(this.activeCards);
  }

  private removeActiveCards(cardIds: string[]) {
    cardIds.forEach((cardId) => this.activeCardIds.delete(cardId));
    this.onCardChanged.call(this.activeCards);
  }
}
