import { WebsocketRequest } from '../WebsocketRequest';
import { WebsocketCategory } from '../enums/WebsocketCategory';
import { ConfigurationModel } from '../../models/ConfigurationModel';
import { StartRecognizeRequestBody } from './request-body/StartRecognizeRequestBody';
import { PersonaRequestBody } from './request-body/PersonaRequestBody';
import { GetVariablesRequestBody } from './request-body/GetVariablesRequestBody';
import { SetVariablesRequestBody } from './request-body/SetVariablesRequestBody';
import { GetVariablesListRequestBody } from './request-body/GetVariablesListRequestBody';
import { GetModelVariablesListRequestBody } from './request-body/GetModelVariablesListRequestBody';
import { GetModelChildrenRequestBody } from './request-body/GetModelChildrenRequestBody';
import { GetModelFilterSearchResultRequestBody } from './request-body/GetModelFilterSearchResultRequestBody';
import { GetModelVariableFilterSearchResultRequestBody } from './request-body/GetModelVariableFilterSearchResultRequestBody';
import { StopBlProfilingRequestBody } from './request-body/StopBlProfilingRequestBody';
import { StartSpeakingRequestBody } from './request-body/StartSpeakingRequestBody';
import { ConversationSendRequestBody } from './request-body/ConversationSendRequestBody';
import { ConversationSetVariablesRequestBody } from './request-body/ConversationSetVariablesRequestBody';
import { PlayAnimationRequestBody } from './request-body/PlayAnimationRequestBody';
import { MonitorSetRequestBody } from './request-body/MonitorSetRequestBody';
import { UpdateContentAwarenessRequestBody } from './request-body/UpdateContentAwarenessRequestBody';

/**
 * @public
 */
export type SceneRequestBody =
  | ConfigurationModel
  | StartRecognizeRequestBody
  | PersonaRequestBody
  | GetVariablesRequestBody
  | SetVariablesRequestBody
  | GetVariablesListRequestBody
  | GetModelVariablesListRequestBody
  | GetModelChildrenRequestBody
  | GetModelFilterSearchResultRequestBody
  | GetModelVariableFilterSearchResultRequestBody
  | StopBlProfilingRequestBody
  | StartSpeakingRequestBody
  | ConversationSendRequestBody
  | ConversationSetVariablesRequestBody
  | PlayAnimationRequestBody
  | MonitorSetRequestBody
  | UpdateContentAwarenessRequestBody;

/**
 * @public
 */
export interface SceneRequest extends WebsocketRequest {
  body: SceneRequestBody;
  category: WebsocketCategory.Scene;
  transaction?: string;
}
