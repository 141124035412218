/**
 * @module smwebsdk
 */

/*
 * Copyright 2020 Soul Machines Ltd. All Rights Reserved.
 */

import { Deferred } from './Deferred';
import { Features } from './Features';
import { Logger, LogLevel } from './utils/Logger';
import { makeError } from './utils/make-error';
import { WebsocketResponse } from './websocket-message/index';

export interface MessageFunction {
  (message: string): void;
}

export interface WebsocketFunction {
  (message: WebsocketResponse): void;
}

export interface SessionFunction {
  (
    resumeRequested: boolean,
    isResumedSession: boolean,
    server: string,
    sessionId: string
  ): void;
}

/**
 *  WebSocketSession class
 */
export class WebSocketSession {
  private _serverUri: string;
  private _accessToken: string;
  private _serverConnection!: WebSocket;
  private _sessionId!: string;
  private _outgoingQueue: any[] = [];

  private _onConnectedStorage: SessionFunction = (
    resumeRequested: boolean,
    isResumedSession: boolean,
    server: string,
    sessionId: string
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {};
  private _onClose: MessageFunction;
  private _onMessage: WebsocketFunction;
  private _sessionError: MessageFunction;

  private _features: Features;
  private _pendingLog: string[] = [];
  private _closed = false;

  private _shouldLogToServer = false;

  constructor(
    serverUri: string,
    accessToken: string,
    private logger = new Logger()
  ) {
    this._serverUri = serverUri;
    this._accessToken = accessToken;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onClose = (reason: string) => {}; // owner specifies custom close method
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onMessage = (message: WebsocketResponse) => {}; // owner specifies custom message handler

    this._sessionError = (error: string) => {
      // owner can specify custom session error handler
      this.logger.log('error', `session error: ${error}`);
    };

    this._features = new Features();
  }

  set onConnected(sessionFunction: SessionFunction) {
    this._onConnectedStorage = sessionFunction;
  }

  set onClose(closeFunction: MessageFunction) {
    this._onClose = closeFunction;
  }

  set onMessage(messageFunction: WebsocketFunction) {
    this._onMessage = messageFunction;
  }

  /**
   * @deprecated use setLogging(boolean).
   */
  set loggingEnabled(enable: boolean) {
    this.logger.log(
      'warn',
      'loggingEnabled is deprecated and will be removed in a future version. Please use setLogging(boolean)'
    );
    this.logger.enableLogging(enable);
  }

  get loggingEnabled(): boolean {
    return this.logger.isEnabled;
  }

  public setMinLogLevel(level: LogLevel) {
    this.logger.setMinLogLevel(level);
  }

  public setLogging(enable: boolean) {
    this.logger.enableLogging(enable);
  }

  public log(text: string) {
    if (this.loggingEnabled) {
      if (this._shouldLogToServer) {
        this.logToServer(text);
      } else {
        this.logger.log('log', text);
      }
    }
  }

  private logToServer(text: string) {
    if (this.sessionId) {
      this.sendlogMessage([text]);
    } else {
      this._pendingLog.push(text);
    }
  }

  public sendlogMessage(textArray: string[]): void {
    if (this._sessionId && textArray && textArray.length > 0) {
      const payload: any = {
        category: 'diagnostics',
        kind: 'event',
        name: 'log',
        body: { name: 'browser', text: textArray },
      };
      this.sendMessage(payload);
    }
  }

  public async connect(): Promise<string | undefined> {
    const deferred = new Deferred<any>();
    this._closed = false;
    if (
      this._serverUri &&
      (this._serverUri.startsWith('ws:') || this._serverUri.startsWith('wss:'))
    ) {
      // A server uri has been specified, continue with the connection
      this.connectByWebSocket(deferred);
    }
    return deferred.promise;
  }

  private connectByWebSocket(deferred: Deferred<any>): void {
    this.log(`connecting to: ${this._serverUri}`);
    if (!this._accessToken) {
      this._serverConnection = new WebSocket(this._serverUri);
    } else {
      this._serverConnection = new WebSocket(
        this._serverUri + '?access_token=' + this._accessToken
      );
    }

    this._serverConnection.onmessage = (msg: MessageEvent) => {
      this.gotMessageFromServer(msg, deferred);
    };

    this._serverConnection.onerror = (event) => {
      if (deferred.isPending()) {
        deferred.reject(
          makeError('websocket failed', 'serverConnectionFailed')
        );
      }
    };

    // wait for the websocket to open, then continue with setup
    this._serverConnection.onopen = (event: Event) => {
      this.log('websocket open');
      deferred.resolve();
    };

    // setup a close handler
    this._serverConnection.onclose = (event: CloseEvent) => {
      this.log(
        `websocket closed: code(${event.code}), reason(${event.reason}), clean(${event.wasClean})`
      );
      if (!deferred.isRejected) {
        this.close(false, 'normal', deferred);
      }
    };
  }

  private gotMessageFromServer(
    websocket_message: MessageEvent,
    deferred?: Deferred<any>
  ): void {
    const raw_text = websocket_message.data;
    this.log(`message received: ${raw_text}`);
    const message = JSON.parse(raw_text);

    const category = message.category;
    const name = message.name;
    const body = message.body;

    if (category !== 'webrtc') {
      // forward on non-webrtc messages (e.g. scene)
      this._onMessage(message);
      return;
    }

    if (message.kind !== 'event') {
      // currently ignore requests and responses
      return;
    }

    if (name === 'accepted') {
      this.log(`accepted, session_id = ${body.sessionId}`);
      this._sessionId = body.sessionId;

      // The session has been accepted, send any outgoing queued messages
      for (let i = 0; i < this._outgoingQueue.length; i++) {
        this._outgoingQueue[i].body.sessionId = this._sessionId;
        this.sendMessage(this._outgoingQueue[i]);
      }
      this._outgoingQueue = [];

      // Send all pending log messages
      this.sendlogMessage(this._pendingLog);
      this._pendingLog = [];
    } else if (name === 'close') {
      this.close(false, body.reason, deferred);
    }
  }

  public sendMessage(message: any): void {
    if (!this._serverConnection) {
      return;
    }

    if (this._serverConnection.readyState === WebSocket.OPEN) {
      // connected
      this._serverConnection.send(JSON.stringify(message));
    } else {
      this.log(`not ready, discarding message: ${message}`);
    }
  }

  public close(
    sendRtcClose = true,
    reason = 'normal',
    deferred?: Deferred<any>
  ) {
    if (this._closed) {
      return;
    }

    this._closed = true;

    if (deferred) {
      if (deferred.isResolved()) {
        this._onClose(reason);
      } else {
        deferred.reject(makeError('websocket closed: ' + reason, reason));
      }
    }

    if (this._serverConnection) {
      this.log('closing server connection');
      this._serverConnection.close();
    }
  }

  get serverConnection(): WebSocket {
    return this._serverConnection;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  get peerConnection(): RTCPeerConnection | null {
    return null;
  }

  get features(): Features {
    return this._features;
  }

  public sendRtcEvent(name: string, body: any) {
    // NOOP: Stuff for compatibility with Session in Scene
  }

  public sendVideoBounds(widthIgnored: number, heightIgnored: number) {
    // NOOP: Stuff for compatibility with Session in Scene
  }

  public sendUserText(text: string): void {
    this.logger.log('error', 'WebSocketSession discarding text: ' + text);
  }

  get microphoneMuteDelay(): number | undefined {
    return undefined;
  }

  get microphoneMuted(): boolean | null {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set microphoneMuted(mute: boolean | null) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set onUserText(userTextFunction: MessageFunction) {}

  get isMicrophoneConnected(): boolean | null {
    return null;
  }

  get isCameraConnected(): boolean | null {
    return null;
  }

  get offsetX(): number {
    return 0;
  }

  get offsetY(): number {
    return 0;
  }

  isMicrophoneActive(): boolean {
    return false;
  }

  isCameraActive(): boolean {
    return false;
  }

  async setMediaDeviceActive({
    microphone,
    camera,
  }: {
    microphone?: boolean;
    camera?: boolean;
  }): Promise<void> {
    throw makeError(
      'setMediaDeviceActive not supported on WebSocketSession',
      'notSupported'
    );
  }
}
