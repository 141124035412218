import { ContentAwarenessObjectModel } from '../../../models';

/**
 * @public
 */
export interface UpdateContentAwarenessRequestBody {
  viewWidth: number;
  viewHeight: number;
  videoFrame: ContentAwarenessObjectModel;
  content: Array<ContentAwarenessObjectModel>;
}
