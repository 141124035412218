export const debouncedFunction = (
  fn: CallableFunction,
  debounceTimeMs = 500
): CallableFunction => {
  let debounceTimerId = -1;

  return () => {
    if (debounceTimerId) {
      clearTimeout(debounceTimerId);
    }

    debounceTimerId = setTimeout(
      () => fn(),
      debounceTimeMs
    ) as unknown as number;
  };
};
